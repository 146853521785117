body {
  margin: 0;
  padding: 0;
  font-family: 'Courier New', Courier, monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url(/static/media/bg.f0dac898.png);
  background-repeat: repeat;
}


.app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(5px + 3vmin);
  color: white;
}

.logo {
  width: 32vmin;
  opacity: 0.9;
}

.link {
  padding: 0.5rem;
  display: flex;
  align-items: center;
  text-align: left;
  text-decoration: none;
  color: white;
}
.link:hover {
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 5px;
}
.link img {
  width: auto;
  height: calc(10px + 2vmin);
  margin-right: 0.5rem;
}
.link span {
  height: calc(10px + 2vmin);
  line-height: calc(10px + 2vmin);
}

