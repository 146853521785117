body {
  margin: 0;
  padding: 0;
  font-family: 'Courier New', Courier, monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url("./assets/bg.png");
  background-repeat: repeat;
}

